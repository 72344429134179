<template>
  <div>
    <div>
      <slot />
    </div>
    <ClientOnly>
      <SnackBar />
    </ClientOnly>
  </div>
</template>
